import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./assets/css/verification.css";
import "./assets/css/form.css";
import { Form } from "./pages/form.js";
import NotFound from "./pages/notFound.js";
import PrivateRoute from "./component/privateRoute.js";
import PublicRoute from "./component/publicRoute.js";
import VerificationForm from "./pages/verificationPage.js";
import ResetPassword from "./pages/resetPassword.js";
import Dashboard from "./pages/dashboard.js";
import { Registry } from "./pages/registry.js";
import Payment from "./pages/payment.js";
import AdminLogin from "./pages/adminLogin.js";
import RegistriesList from "./pages/registriesList.js";
import UserRegistries from "./pages/userRegistries.js";
import AdminRoute, { GuestRoute, UserRoute } from "./component/adminRoute.js";
import UserProfile from "./pages/userProfile.js";
import AdminProfile from "./pages/adminProfile.js";
import CertificateList from "./pages/certificateList.js";
import AddDocument from "./pages/addDocument.js";
import About from "./pages/about.js";
import Account from "./pages/account.js";
import Home from "./pages/home.js";
import SingleForm from "./pages/singleForm.js";
import MultipleForm from "./pages/multipleForm.js";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/login" element={<Form />} />
          <Route path="/admin" element={<AdminLogin />} />
          <Route
            path="/verify/:verificationToken"
            element={<VerificationForm />}
          />
          <Route
            path="/reset-password/:verificationToken"
            element={<ResetPassword />}
          />
        </Route>
        <Route element={<PrivateRoute />}>
        
        <Route element={<GuestRoute />}>
            <Route path="/registry" element={<Registry />} />
          </Route>
          <Route element={<AdminRoute />}>
            <Route path="/admin/dashboard" element={<Dashboard />} />
            <Route path="/admin/registries" element={<RegistriesList />} />
            <Route path="/admin/profile" element={<AdminProfile />} />
          </Route>
          <Route element={<UserRoute />}>
            {/* <Route path="/user/registries" element={<UserRegistries />} /> */}
            {/* <Route path="/user/profile" element={<UserProfile />} /> */}
          </Route>
        </Route>
        <Route path="/" element={<Home />} />
        <Route path="/add-document" element={<AddDocument />} />
        <Route path="/about" element={<About />} />
        <Route path="/registries" element={<Account />} />
        <Route path="/single-form" element={<SingleForm />} />
        <Route path="/multiple-form" element={<MultipleForm />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
