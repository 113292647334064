import React from "react";
import Sidebar from "../component/sidebar.js";
import Header from "../component/header.js";
import AdminTables from "../component/adminTable.js";
import "../assets/css/dashboard.css";

function Dashboard() {
  return (
    <div className="dashboard">
      <Sidebar />
      <div className="main-dashboard">
        <div className="header-div">
          <Header isAdmin={true} />
        </div>
        <div className="table-div">
          <AdminTables />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
