import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { HiCheck } from "react-icons/hi";
import TopBar from "../component/topbar";
import "../assets/css/payment.css";

const serverBaseUrl = process.env.REACT_APP_BACKEND_SERVER_URL;
const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

const Payment = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [alertData, setAlertData] = useState(false);
  const [cards, setCards] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleCheckout = async (subscriptionId) => {
    if (!token) {
      localStorage.setItem("redirected_url", window.location.pathname);
      setAlertData("Please sign in first");
      setTimeout(() => {
        setAlertData(null);
        navigate("/login");
      }, 3000);
      return;
    }
    const stripe = await loadStripe(stripePublicKey);
    const body = { subscriptionId, registryId: id };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    try {
      const response = await fetch(
        `${serverBaseUrl}/payment/create-checkout-session`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
        }
      );

      const responseData = await response.json();
      if (response.status === 200) {
        const session = responseData?.data;
        await stripe.redirectToCheckout({
          sessionId: session.id,
        });
      } else if (response.status === 400) {
        setAlertData(responseData.message || "An error occurred");
        setTimeout(() => setAlertData(null), 3000);
        setCards([]);
      } else {
        setAlertData(responseData.message || "An error occurred");
        setTimeout(() => setAlertData(null), 3000);
      }
    } catch (error) {
      setAlertData(error.message || "An error occurred");
      setTimeout(() => setAlertData(null), 3000);
    }
  };

  const getCardData = async () => {
    try {
      if (id && !token) {
        localStorage.setItem("redirected_url", window.location.pathname);
        setAlertData("Please sign in first");
        setTimeout(() => {
          setAlertData(null);
          navigate("/login");
        }, 3000);
        return;
      }
      const body = { id };
      if (user) {
        body.email = user.email;
      }
      const queryParams = new URLSearchParams(body).toString();
      const response = await fetch(
        `${serverBaseUrl}/payment/cards?${queryParams}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = await response.json();
      if (response.status === 200) {
        setCards(responseData.data.cards);
      } else {
        setAlertData(responseData.message || "An error occurred");
        setTimeout(() => setAlertData(null), 3000);
      }
    } catch (error) {
      setAlertData(error.message || "An error occurred");
      setTimeout(() => setAlertData(null), 3000);
    }
  };

  useEffect(() => {
    getCardData();
  }, [id]);

  return (
    <>
      {alertData && <div className="error form-error">{alertData}</div>}
      <div className="page">
        <TopBar />
        <div className="page-main-div">
          <div className="payment-container">
            <h1>Payment Page</h1>
            <p className="text-gray">
              The cost of Registering your Titles should not be cost
              prohibitive.
              <br />
              Register Now.
            </p>
            <div className="card-container">
              {cards?.map((card) => (
                <div className="card" key={card?.id}>
                  <div style={{ width: "100%" }}>
                    <h2>{card?.name}</h2>
                    <p>{card?.tag_line}</p>
                    <p className="price">
                      ${card?.price} <span className="small">USD</span>
                    </p>
                    <p className="badges w-100">
                      {card?.description?.split(", ")?.map((badge, index) => (
                        <span className="w-100" key={index}>
                          <p className="w-75">{badge}</p>
                          <p className="w-25">
                            <HiCheck
                              size={30}
                              style={{
                                color: "#5FD193",
                                backgroundColor: "#C6EDD9",
                                borderRadius: "50%",
                                padding: "5px",
                              }}
                            />
                          </p>
                        </span>
                      ))}
                    </p>
                  </div>
                  <button onClick={() => handleCheckout(card?.id)}>
                    Select
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
