import React, { useState } from "react";
import { Login } from "../component/login";
import { Signup } from "../component/signup";
import { ForgotPassword } from "../component/forgotPassword";
import TopBar from "../component/topbar";
import Footer from "../component/footer"
import LeftComponent from "../component/leftComponent";

export function Form() {
  const [formType, setFormType] = useState("login");

  const renderForm = () => {
    switch (formType) {
      case "login":
        return <Login setFormType={setFormType} />;
      case "signup":
        return <Signup setFormType={setFormType} />;
      case "forgotPassword":
        return <ForgotPassword />;
      default:
        return <Login setFormType={setFormType} />;
    }
  };

  return (
    <div className="form-page-main-div">
      <TopBar />
      <div className="form-page">
        <LeftComponent />
        <div className="right-component">
          {renderForm()}
          {formType === "login" && (
            <p className="account-para">
              Not a member yet?{" "}
              <span
                onClick={() => setFormType("signup")}
                className="navigate-btn"
              >
                Create Account
              </span>
            </p>
          )}
          {formType === "forgotPassword" && (
            <p className="account-para">
              <span
                onClick={() => setFormType("login")}
                className="navigate-btn"
              >
                Sign In
              </span>
            </p>
          )}
          {formType === "signup" && (
            <p className="account-para">
              <span
                onClick={() => setFormType("login")}
                className="navigate-btn"
              >
                Sign In
              </span>
            </p>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
