import React from "react";
import TopBar from "../component/topbar.js";
import blueBoardSvg from "../assets/vectors/img-7.svg";
import orangeBoardSvg from "../assets/vectors/img-8.svg";
import lightGreenBoardSvg from "../assets/vectors/img-9.svg";
import majorelleBlueBoardSvg from "../assets/vectors/img-10.svg";
import redBoardSvg from "../assets/vectors/img-11.svg";
import grayBoardSvg from "../assets/vectors/img-12.svg";
import skyBlueBoardSvg from "../assets/vectors/img-13.svg";
import greenBoardSvg from "../assets/vectors/img-14.svg";
import lightBlueBoardSvg from "../assets/vectors/img-15.svg";
import lightRedBoardSvg from "../assets/vectors/img-16.svg";
import neonBlueBoardSvg from "../assets/vectors/img-17.svg";
import purpleBoardSvg from "../assets/vectors/img-18.svg";
import "../assets/css/multipleForm.css";

const MultipleForm = () => {
  return (
    <div className="page multiple-form-page">
      <TopBar />
      <div className="page-main-div">
        <div className="containers">
          <div className="card">
            <div
              className="icon-container"
              style={{ backgroundColor: "#E9EDF7" }}
            >
              <img
                src={blueBoardSvg}
                alt="Board"
                style={{ width: "34px", height: "34px" }}
              />
            </div>
            <div className="text-container">
              <h3>
                Screenplays <br /> Books <br />
                Text{" "}
              </h3>
            </div>
          </div>
          <div className="card">
            <div
              className="icon-container"
              style={{ backgroundColor: "#FEEFE9" }}
            >
               <img
                src={orangeBoardSvg}
                alt="Board"
                style={{ width: "34px", height: "34px" }}
              />
            </div>
            <div className="text-container">
              <h3>NDAs</h3>
            </div>
          </div>
          <div className="card">
            <div
              className="icon-container"
              style={{ backgroundColor: "#EFF7F0" }}
            >
              <img
                src={lightGreenBoardSvg}
                alt="Board"
                style={{ width: "36px", height: "34px" }}
              />
            </div>
            <div className="text-container">
              <h3>Contracts</h3>
            </div>
          </div>
          <div className="card">
            <div
              className="icon-container"
              style={{ backgroundColor: "#EEF1FA" }}
            >
              <img
                src={majorelleBlueBoardSvg}
                alt="Board"
                style={{ width: "36px", height: "34px" }}
              />
            </div>
            <div className="text-container">
              <h3>Wills</h3>
            </div>
          </div>
          <div className="card">
            <div
              className="icon-container"
              style={{ backgroundColor: "#FBE9E9" }}
            >
              <img
                src={redBoardSvg}
                alt="Board"
                style={{ width: "36px", height: "34px" }}
              />
            </div>
            <div className="text-container">
              <h3>Photograghs</h3>
            </div>
          </div>
          <div className="card">
            <div
              className="icon-container"
              style={{ backgroundColor: "#EEEEEE" }}
            >
              <img
                src={grayBoardSvg}
                alt="Board"
                style={{ width: "36px", height: "34px" }}
              />
            </div>
            <div className="text-container">
              <h3>Deeds</h3>
            </div>
          </div>
          <div className="card">
            <div
              className="icon-container"
              style={{ backgroundColor: "#E7F7FC" }}
            >
              <img
                src={skyBlueBoardSvg}
                alt="Board"
                style={{ width: "36px", height: "34px" }}
              />
            </div>
            <div className="text-container">
              <h3>Lease/Mortgage</h3>
            </div>
          </div>
          <div className="card">
            <div
              className="icon-container"
              style={{ backgroundColor: "#E9F7F2" }}
            >
              <img
                src={greenBoardSvg}
                alt="Board"
                style={{ width: "36px", height: "34px" }}
              />
            </div>
            <div className="text-container">
              <h3>Pre-Nups</h3>
            </div>
          </div>
          <div className="card">
            <div
              className="icon-container"
              style={{ backgroundColor: "#E6F6FF" }}
            >
              <img
                src={lightBlueBoardSvg}
                alt="Board"
                style={{ width: "36px", height: "34px" }}
              />
            </div>
            <div className="text-container">
              <h3>POA</h3>
            </div>
          </div>
          <div className="card">
            <div
              className="icon-container"
              style={{ backgroundColor: "#FFEEEE" }}
            >
              <img
                src={lightRedBoardSvg}
                alt="Board"
                style={{ width: "36px", height: "34px" }}
              />
            </div>
            <div className="text-container">
              <h3>Work</h3>
            </div>
          </div>
          <div className="card">
            <div
              className="icon-container"
              style={{ backgroundColor: "#E8F0FE" }}
            >
              <img
                src={neonBlueBoardSvg}
                alt="Board"
                style={{ width: "36px", height: "34px" }}
              />
            </div>
            <div className="text-container">
              <h3>
                Employment <br /> Forms
              </h3>
            </div>
          </div>
          <div className="card">
            <div
              className="icon-container"
              style={{ backgroundColor: "#F6EAF8" }}
            >
              <img
                src={purpleBoardSvg}
                alt="Board"
                style={{ width: "36px", height: "34px" }}
              />
            </div>
            <div className="text-container">
              <h3>Taxes</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleForm;
