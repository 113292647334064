import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PublicRoute = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const admin = JSON.parse(localStorage.getItem("admin"));
  if (user) {
    return <Navigate to="/registries" />;
  } else if (admin) {
    return <Navigate to="/admin/dashboard" />;
  } else {
    return <Outlet />;
  }
};

export default PublicRoute;
