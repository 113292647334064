import React from "react";
import TopBar from "../component/topbar";
import Footer from "../component/footer"
import illustration from "../assets/vectors/img-3.svg";
import RegistryForm from "../component/registry";

export function Registry() {
  return (
    <div className="form-page-main-div">
      <TopBar />
      <div className="form-page">
        <div className="left-component">
          <h1>Sign in Anonymously</h1>
          <p>
            You may view Title Names and Registrars, in our Title Registry
            Database, anonymously.
          </p>
          <img
            src={illustration}
            alt="tr-illustration"
            className="illustration"
          />
          <p className="reserved-rights">
            All Rights Reserved to Title Registery
          </p>
        </div>
        <div className="right-component">
          <RegistryForm />
        </div>
      </div>
      <style jsx>{`
        .left-component {
          width: 50vw;
          padding-inline: 7.2% 3%;
          padding-top: 21vh;
        }

        .left-component h1 {
          font-size: 30px;
          letter-spacing: 1.1px;
        }

        .left-component p {
          font-size: 13px;
          font-weight: 600;
          margin-top: 3%;
          width: 80%;
          letter-spacing: 0.6px;
        }

        .illustration {
          width: 450px;
          margin: 0%;
          margin-top: -3%;
        }

        .right-component {
          width: 50vw;
          height: max-content;
          padding-inline: 5%;
          padding-top: 10vh;
          padding-bottom: 5%;
        }

        .flex {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          margin-top: 3%;
        }

        .top-margin {
          margin-top: 3%;
        }

        .signup-form {
          width: 90%;
        }

        .signup-form h2 {
          font-size: 26px;
        }

        .signup-form h4 {
          font-weight: normal;
          margin-top: 3%;
        }

        .signup-form h5 {
          margin-top: 2%;
          font-size: 15px;
          font-weight: 600;
        }

        .signup-form .main-para {
          margin: 4% 0%;
        }

        .half-div {
          width: 46%;
        }

        .one-third-div {
          width: 31%;
        }

        .registry-input-div select,
        .registry-input-div input {
          height: 50px;
        }

        .registry-input-div select,
        .registry-input-div .dropdown,
        .registry-input-div input {
          background: none;
          border: 1px solid #8d8d8d;
          border-radius: 7px;
          padding-inline: 5%;
          margin: 10px 0px;
          font-size: 14px;
        }

        .login-btn {
          font-size: 14px;
          height: 40px;
        }

        @media (max-width: 1024px) {
          .illustration {
            width: 420px;
            margin-left: -10%;
          }

          .top-margin {
            margin-top: 5%;
          }

          .right-component {
            padding-bottom: 2%;
          }
        }

        @media (max-width: 912px) {
          .right-component {
            width: 100%;
            padding-inline: 10%;
          }
        }

        @media (max-width: 540px) {
          .right-component {
            padding-top: 5vh;
          }
        }

        @media (max-width: 430px) {
          .signup-form {
            width: 100%;
          }
          .registry-input-div label {
            font-size: 12px;
          }
        }

        @media (max-width: 390px) {
          .right-component {
            padding: 5%;
          }
        }
      `}</style>
      <Footer />
    </div>
  );
}
