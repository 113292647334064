import React, { useState, useEffect, useRef } from "react";

const AddUser = ({ isOpen, onClose, onSave, disabled, fieldErrors }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    date_of_birth: "",
    phone_number: "",
  });

  const modalRef = useRef(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content" ref={modalRef}>
        <h2>Add User</h2>
        <form onSubmit={handleSubmit}>
          <div className="modal-input-div">
            <label>
              First Name
              <span className="required-asterisk">*</span>
            </label>
            <input
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              required
            />
          </div>
          {fieldErrors.firstName && (
            <p className="joi-error-message joi-error-right">
              {fieldErrors.firstName[0]}
            </p>
          )}
          <div className="modal-input-div">
            <label>
              Last Name
              <span className="required-asterisk">*</span>
            </label>
            <input
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              required
            />
          </div>
          {fieldErrors.lastName && (
            <p className="joi-error-message joi-error-right">
              {fieldErrors.lastName[0]}
            </p>
          )}
          <div className="modal-input-div">
            <label>
              Email
              <span className="required-asterisk">*</span>
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          {fieldErrors.email && (
            <p className="joi-error-message joi-error-right">
              {fieldErrors.email[0]}
            </p>
          )}
          <div className="modal-input-div">
            <label>
              Phone Number
              <span className="required-asterisk">*</span>
            </label>
            <input
              type="text"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              maxLength="12"
              required
            />
          </div>
          {fieldErrors.phoneNumber && (
            <p className="joi-error-message joi-error-right">
              {fieldErrors.phoneNumber[0]}
            </p>
          )}
          <div className="modal-input-div">
            <label>
              Date of Birth
              <span className="required-asterisk">*</span>
            </label>
            <input
              type="date"
              name="date_of_birth"
              value={formData.date_of_birth}
              onChange={handleChange}
              required
            />
          </div>
          {fieldErrors.dateOfBirth && (
            <p className="joi-error-message joi-error-right">
              {fieldErrors.dateOfBirth[0]}
            </p>
          )}
          <div className="button-div">
            <button type="submit" disabled={disabled}>
              Add
            </button>
            <button type="button" onClick={onClose} disabled={disabled}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUser;
