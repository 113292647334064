import React, { useState, useEffect, useRef } from "react";
import "../assets/css/viewRegistry.css";

const ViewRegistry = ({ isOpen, onClose, registryDetails }) => {
  const modalRef = useRef(null);
  const pdfRef = useRef(null);
  const [certificateUrl, setCertificateUrl] = useState(null);
  const [selectedUrl, setSelectedUrl] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(isOpen);

  const [formData, setFormData] = useState({
    titleItem: "",
    materialType: "",
    intendedMedium: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    country: "",
    province: "",
    city: "",
    zip: "",
    identification: "",
    phoneNumber: "",
    socialSecurityNumber: "",
    documentTitle: "",
    documentDescription: "",
  });

  const handleClickOutsidePdf = (event) => {
    if (pdfRef.current && !pdfRef.current.contains(event.target)) {
      setSelectedUrl(null);
      setIsModalVisible(true);
    }
  };

  useEffect(() => {
    if (selectedUrl) {
      setIsModalVisible(false);
      document.addEventListener("mousedown", handleClickOutsidePdf);
    } else {
      setIsModalVisible(true);
      document.removeEventListener("mousedown", handleClickOutsidePdf);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutsidePdf);
    };
  }, [selectedUrl]);

  useEffect(() => {
    if (registryDetails) {
      setCertificateUrl(
        registryDetails?.certificate_url &&
          registryDetails?.registry_status === "active"
          ? registryDetails?.certificate_url
          : ""
      );
      setFormData({
        titleItem: registryDetails.title_item,
        materialType: registryDetails.material_type,
        intendedMedium: registryDetails.intended_medium,
        firstName: registryDetails.first_name,
        middleName: registryDetails.middle_name,
        lastName: registryDetails.last_name,
        email: registryDetails.email,
        country: registryDetails.country,
        province: registryDetails.province,
        city: registryDetails.city,
        zip: registryDetails.zip,
        identification: registryDetails.identification,
        phoneNumber: registryDetails.phone_number,
        socialSecurityNumber: registryDetails.social_security_number,
        documentTitle: registryDetails.document_title,
        documentDescription: registryDetails.document_description,
      });
    }
  }, [registryDetails]);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setCertificateUrl(null);
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const openCertificate = () => {
    setSelectedUrl(certificateUrl);
  };

  const savePdf = async () => {
    try {
      const response = await fetch(selectedUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const randomFileName = `certificate_${Date.now()}.pdf`;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", randomFileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    } finally {
      setSelectedUrl(selectedUrl);
    }
  };

  const closePdf = () => {
    setSelectedUrl(null);
    setIsModalVisible(true);
  };

  if (!isOpen && !selectedUrl) return null;

  return (
    <>
      {isModalVisible && (
        <div className="view-registry">
          <div className="modal-overlay">
            <div className="modal-content view-reg-form" ref={modalRef}>
              <div className="modal-header">
                <h2>View Registry</h2>
                <button className="close-button" onClick={onClose}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <h5>Register Material</h5>
                  <div className="registry-grid">
                    <div className="registry-input-div">
                      <label htmlFor="intended-medium">Intended Medium</label>
                      <input
                        disabled
                        id="intended-medium"
                        value={formData.intendedMedium}
                      />
                    </div>
                    <div className="registry-input-div">
                      <label htmlFor="material-type">Material Type</label>
                      <input
                        disabled
                        id="material-type"
                        value={formData.materialType}
                      />
                    </div>
                    <div className="registry-input-div">
                      <label htmlFor="title-item">Title of Item</label>
                      <input
                        disabled
                        id="title-item"
                        value={formData.titleItem}
                      />
                    </div>
                  </div>
                  <hr />
                  <h5>Personal Information</h5>
                  <div className="registry-grid">
                    <div className="registry-input-div">
                      <label htmlFor="social-security-number">
                        Social Security Number
                      </label>
                      <input
                        disabled
                        id="social-security-number"
                        value={formData.socialSecurityNumber}
                      />
                    </div>
                    <div className="registry-input-div">
                      <label htmlFor="identification">Identification</label>
                      <input
                        disabled
                        id="identification"
                        value={formData.identification}
                      />
                    </div>
                    <div className="registry-input-div">
                      <label htmlFor="first-name">First Name</label>
                      <input
                        disabled
                        id="first-name"
                        value={formData.firstName}
                      />
                    </div>
                    {formData.middleName !== "" && (
                      <div className="registry-input-div">
                        <label htmlFor="middle-name">Middle Name</label>
                        <input
                          disabled
                          id="middle-name"
                          value={formData.middleName}
                        />
                      </div>
                    )}
                    <div className="registry-input-div">
                      <label htmlFor="last-name">Last Name</label>
                      <input
                        disabled
                        id="last-name"
                        value={formData.lastName}
                      />
                    </div>
                    <div className="registry-input-div">
                      <label htmlFor="country">Country</label>
                      <input disabled id="country" value={formData.country} />
                    </div>
                    <div className="registry-input-div">
                      <label htmlFor="state">State/Province</label>
                      <input disabled id="state" value={formData.province} />
                    </div>
                    <div className="registry-input-div">
                      <label htmlFor="city">City</label>
                      <input disabled id="city" value={formData.city} />
                    </div>
                    <div className="registry-input-div">
                      <label htmlFor="zip">Zip</label>
                      <input disabled id="zip" value={formData.zip} />
                    </div>
                    {formData.phoneNumber !== "" && (
                      <div className="registry-input-div">
                        <label htmlFor="phone-number">Phone Number</label>
                        <input
                          disabled
                          id="phone-number"
                          value={formData.phoneNumber}
                        />
                      </div>
                    )}
                    <div className="registry-input-div">
                      <label htmlFor="email">Email</label>
                      <input disabled id="email" value={formData.email} />
                    </div>
                  </div>
                  <hr />
                  <h5>Document</h5>
                  <div className="registry-input-div">
                    <label htmlFor="document-title">Document Title</label>
                    <input
                      disabled
                      id="document-title"
                      value={formData.documentTitle}
                    />
                  </div>
                  <div className="registry-input-div">
                    <label htmlFor="document-description">
                      Document Description
                    </label>
                    <textarea
                      disabled
                      id="document-description"
                      className="p-0 px-2"
                      value={formData.documentDescription}
                      cols="5"
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {certificateUrl ? (
                  <button
                    className="btn btn-secondary"
                    onClick={openCertificate}
                  >
                    Open Certificate
                  </button>
                ) : null}
                <button className="btn btn-primary" onClick={onClose}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {selectedUrl && (
        <div className="modal-overlay">
          <div
            className="model-inside-div"
            style={{
              width: "50%",
              height: "80%",
              position: "relative",
            }}
            ref={pdfRef}
          >
            <iframe
              src={selectedUrl}
              width="100%"
              height="100%"
              className="show-pdf"
            />


            {/* <div className="pdf-buttons">
              <button
                className="btn btn-primary btn-save"
                onClick={savePdf}
                type="button"
              >
                Save
              </button>

              <button
                className="btn btn-danger button-close"
                onClick={closePdf}
              >
                Close
              </button>
            </div> */}


          </div>
        </div>
      )}
    </>
  );
};

export default ViewRegistry;
