import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import TopBar from "../component/topbar.js";
import Footer from "../component/footer.js";
import "../assets/css/about.css";

const About = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [documentDescription, setDocumentDescription] = useState("");

  const handleDocumentDescriptionChange = (event) => {
    setDocumentDescription(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(firstName, documentDescription);
  };

  return (
    <div className="page about-page">
      <TopBar />
      <div className="page-main-div">
        <div className="container">
          <div className="about bg-white w-50">
            <div>
              <h2>About</h2>
              <h3>Title Registry</h3>
              <p className="bold-para">
                Register Your Digital Papers and Files with Title Registry.
              </p>
              <p>
                If you are a writer, artist, photographer, engineer, architect,
                student, scientist, or any other content creator seeking a
                reputable title registration firm, go no further because our
                company, Title Registry, has your back. We are a file
                depository, meaning you can store all your vital files with us
                for a very low inexpensive price.
              </p>
              <p className="bold-para">
                What Can You Register With Title Registry?
              </p>
              <p>
                Any file can be registered to help you keep track of how your
                work was done. The materials that can be registered at this time
                include scripts, treatments, synopses, and outlines, among other
                things. We will add more registered materials for registration
                in the near future such as NDA, Wills, Deeds, Lease and
                Mortgages.
              </p>
            </div>

            <div className="lower-div">
              <div className="first-div">
                <p>Title Registry LLC</p>
                <p>6161 El Cajon Blvd. </p>
                <p>Suite B, #291</p>
                <p>San Diego, CA</p>
              </div>
              <div className="second-div">
                <p>1-800-336-9932</p>
                <p>Email: info@titleregistry.io</p>
                <p>Project Information</p>
              </div>
            </div>
          </div>
          <div className="contact bg-white w-50">
            <h2>Contact</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <input
                  id="firstName"
                  name="firstName"
                  value={firstName}
                  placeholder="Text here"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Last Name</label>
                <input
                  id="lastName"
                  name="lastName"
                  value={lastName}
                  placeholder="Text here"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  name="email"
                  value={email}
                  placeholder="Text here"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="documentDescription">
                  Document Description
                </label>
                <textarea
                  id="documentDescription"
                  placeholder="Add Text here"
                  rows={5}
                  name="documentDescription"
                  value={documentDescription}
                  onChange={handleDocumentDescriptionChange}
                />
              </div>
              <button type="submit" className="submit-button">
                Submit
              </button>
            </form>
            <div className="go-back">
              <span className="arrow-span">
                <IoMdArrowBack size={20} />
              </span>
              <Link to="/" className="back-button">
                Go Back
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
