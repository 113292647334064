import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const serverBaseUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

export function Login({ setFormType }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [alertData, setAlertData] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const login = async (e) => {
    e.preventDefault();
    try {
      if (!email || !password) {
        setAlertData("Please insert all fields");
        setTimeout(() => setAlertData(false), 3000);
        return;
      }
      setFieldErrors({});
      const response = await fetch(`${serverBaseUrl}/user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      const responseData = await response.json();
      if (response.status === 200) {
        const user = responseData.data.user;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", responseData.data.token);
        setSuccess(responseData.message || "Login successfully");
        setTimeout(() => setSuccess(false), 3000);
        const redirectedUrl = localStorage.getItem("redirected_url");
        if (redirectedUrl) {
          navigate(redirectedUrl);
          localStorage.removeItem("redirected_url");
        } else {
          navigate("/registries");
        }
        return;
      } else if (response.status === 400) {
        const error = typeof responseData.error;
        if (error === "object") {
          setFieldErrors(responseData.error);
          setTimeout(() => setFieldErrors(false), 5000);
        } else {
          setAlertData(responseData.message || "An error occurred");
          setTimeout(() => setAlertData(false), 3000);
        }
      } else {
        setAlertData(responseData?.message || "An error occurred");
        setTimeout(() => setAlertData(false), 3000);
        return;
      }
    } catch (error) {
      setAlertData(`Login error : ${error.message}`);
      setTimeout(() => setAlertData(false), 3000);
      return;
    }
  };

  const signInAnonymously = () => {
    const anonymousUser = {
      email: "anonymous@example.com",
      name: "Anonymous User",
    };
    localStorage.setItem("guest", JSON.stringify(anonymousUser));
    navigate("/");
  };

  return (
    <>
      {alertData && <div className="error form-error">{alertData}</div>}
      {success && <div className="success form-error">{success}</div>}

      <form onSubmit={login} className="login-form">
        <h3>Title Registry</h3>
        <h4>Login</h4>
        <div className="fields">
          <div className="input-div">
            <label htmlFor="email">
              Email <span className="required-asterisk">*</span>
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="example@email.com"
              required
            />
            {fieldErrors.email && (
              <p className="joi-error-message">{fieldErrors.email[0]}</p>
            )}
          </div>
          <div className="input-div">
            <label htmlFor="password">
              Password <span className="required-asterisk">*</span>
            </label>
            <div className="password-input">
              <input
                id="password"
                className="input"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="********"
                required
              />
              <div className="icon-div">
                {showPassword ? (
                  <BsEye
                    size={18}
                    color="#8d8d8d"
                    onClick={togglePasswordVisibility}
                    className="eye-icon"
                  />
                ) : (
                  <BsEyeSlash
                    size={18}
                    color="#8d8d8d"
                    onClick={togglePasswordVisibility}
                    className="eye-icon"
                  />
                )}
              </div>
            </div>
          </div>
          {fieldErrors.password && (
            <p className="joi-error-message">{fieldErrors.password[0]}</p>
          )}
          <div className="remember-forgot">
            <div className="checkbox-div">
              <input className="input" type="checkbox" />
              <p>Remember me</p>
            </div>
            <a href="#" onClick={() => setFormType("forgotPassword")}>
              Forgot Your Password?
            </a>
          </div>
        </div>
        <button type="submit" className="login-btn">
          Login
        </button>
        <button
          type="button"
          className="sign-in-btn"
          onClick={signInAnonymously}
        >
          Search Anonymously
        </button>
      </form>
    </>
  );
}
