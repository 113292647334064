import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FaChevronDown, FaTimes } from "react-icons/fa";
import "../assets/css/topbar.css";

const serverBaseUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

function TopBar() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [detailResults, setDetailResults] = useState(null);
  const [alertData, setAlertData] = useState(null);
  const token = localStorage.getItem("token");
  const dropdownRef = useRef(null);
  const searchDropdownRef = useRef(null);
  const admin = localStorage.getItem("admin");

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
    if (
      searchDropdownRef.current &&
      !searchDropdownRef.current.contains(event.target)
    ) {
      setSearchResults([]);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchInput(value);
    if (value) {
      fetchSearchResults(value);
    } else {
      setSearchResults([]);
    }
  };

  const fetchSearchResults = async (query) => {
    if (query.trim().length > 3) {
      try {
        const response = await fetch(
          `${serverBaseUrl}/registry/search?query=${query}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseData = await response.json();
        if (response.status === 200) {
          setSearchResults(responseData.data.registries);
        } else {
          setAlertData(responseData.message || "An error occurred");
          setTimeout(() => setAlertData(null), 3000);
          setSearchResults([]);
        }
      } catch (error) {
        setAlertData(error.message || "An error occurred");
        setTimeout(() => setAlertData(null), 3000);
        setSearchResults([]);
      }
    }
  };

  const handleResultClick = (result) => {
    setSearchInput("");
    setSearchResults([]);
    setDetailResults(result);
  };

  const handleCloseDetails = () => {
    setDetailResults(null);
  };

  const logout = () => {
    localStorage.clear();
  };

  const formatDate = (dateString) => {
    const [datePart, timePart] = dateString.split("T");
    const [year, month, day] = datePart.split("-");
    const [hour, minute] = timePart.split(":");
    return `${month}/${day}/${year} ${hour}:${minute}`;
  };

  return (
    <>
      {alertData && <div className="error form-error">{alertData}</div>}

      <header className="top-bar">
        <div className="top-bar-div">
          <Link to="/" className="logo">
            Title Registry
          </Link>
          <nav className="links">
            <ul>
              <li>
                <Link to="/registry">Register</Link>
              </li>
              <li>
                <Link to="/about">About/Contact</Link>
              </li>
              <li>
                <span className="account-link">Account</span>{" "}
                <span className="account-dropdown" onClick={toggleDropdown}>
                  <FaChevronDown size={14} />
                </span>
                <div
                  ref={dropdownRef}
                  className={`dropdown-menu ${isDropdownOpen ? "open" : ""}`}
                >
                  <Link to="/registries">Files</Link>
                  <Link onClick={logout} to={admin ? "/admin" : "/login"}>
                    {token ? "Sign out" : "Login"}
                  </Link>
                </div>
              </li>
            </ul>
          </nav>
        </div>

        <div className="search-bar">
          <input
            type="search"
            placeholder="Search..."
            value={searchInput}
            onChange={handleSearchChange}
          />
          <button>Search</button>
          {searchResults.length > 0 && (
            <div
              className="search-results-dropdown position-absolute text-black bg-white overflow-hidden overflow-y-auto"
              ref={searchDropdownRef}
            >
              <ul>
                {searchResults.map((result, index) => (
                  <li key={index} onClick={() => handleResultClick(result)}>
                    {result.title_item}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </header>
      {detailResults && (
        <div className="search-results">
          <button className="close-button" onClick={handleCloseDetails}>
            <FaTimes />
          </button>
          <p className="font-bold">Search Results:</p>
          <ul className="list-disc pl-5">
            <li className="search-result-item mb-2">
              <p className="title-item">{detailResults.title_item}</p>
              <p className="material-type d-flex">
                Material Type:{" "}
                <span className="text-sm fw-normal">
                  {detailResults.material_type}
                </span>
              </p>
              <p className="material-type d-flex">
                Register:{" "}
                <span className="text- fw-normal">
                  {detailResults.first_name + " " + detailResults.last_name}
                </span>
              </p>
              <p className="material-type d-flex">
                Date:{" "}
                <span className="text-sm fw-normal">
                  {formatDate(detailResults.created_at)}
                </span>
              </p>
              <p className="material-type d-flex">
                Register ID:{" "}
                <span className="text-sm fw-normal">
                  {detailResults.registry_id}
                </span>
              </p>
            </li>
          </ul>
        </div>
      )}
    </>
  );
}

export default TopBar;
